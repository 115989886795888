import * as React from "react"
import { ApolloProvider, Query } from "react-apollo"
import apolloClient from "client/apolloClient"
import { getPageQuery } from "gql/queries"
import { SimplePage, SimplePost } from "components/Pages"
import { Loader } from "components/Common"
import { parseSearch } from "common/utils"

const PreviewPage = (props) => {
  if (typeof window === `undefined`) return <Loader />
  const search = parseSearch(props.location.search)
  const type = search.type || `page`

  return (
    <React.Fragment>
      {search.preview_id ? (
        <ApolloProvider client={apolloClient}>
          <Query
            query={getPageQuery(type, true, true)}
            variables={{
              pageId: parseInt(search.preview_id) || 0,
            }}
            fetchPolicy="no-cache"
            notifyOnNetworkStatusChange
          >
            {({ loading, error, data, networkStatus }) => {
              if (error) return `Error!: ${error}`
              if ((loading && networkStatus !== 4) || !data.page)
                return <Loader />
              const pageCountrySlug = data.page.country
                ? data.page.country.slug
                : `us`
              if (data) {
                const { language } = data.page
                const country = props.pageContext.countries.reduce(
                  (acc, ncountry) =>
                    ncountry.slug === pageCountrySlug ? ncountry : acc,
                  {}
                )
                const baseLocaleUrl =
                  language.slug !==
                  (country.defaultLanguage ? country.defaultLanguage.slug : ``)
                    ? `/${language.slug}`
                    : ``
                const pageData = {
                  locale: language.slug,
                  country,
                  commontext:
                    country.settings && country.settings[language.slug]
                      ? country.settings[language.slug]
                      : {},
                  home_url: `/${country.slug}${baseLocaleUrl}`,
                }
                return (
                  <React.Fragment>
                    {type === `page` ? (
                      <SimplePage
                        pageContext={{
                          ...props.pageContext,
                          ...data.page,
                          ...pageData,
                        }}
                      />
                    ) : (
                      <SimplePost
                        pageContext={{
                          ...props.pageContext,
                          ...data.page,
                          ...pageData,
                        }}
                      />
                    )}
                  </React.Fragment>
                )
              }
            }}
          </Query>
        </ApolloProvider>
      ) : (
        <div style={{ align: `center` }}>Preview ID is missing!</div>
      )}
    </React.Fragment>
  )
}

export default PreviewPage
